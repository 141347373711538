@use "../../../../assets/scss/main.scss" as *;

.introductionSection {
  background: $custom-black;
}

.contentWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;

  padding: 0 12px;

  box-sizing: border-box;
  max-width: 1324px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.carousel {
  width: 100%;

  z-index: 0;
}
