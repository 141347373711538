@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  gap: 16px;

  padding: 16px;
  border-radius: 16px;

  background: $whiteGray;

  img {
    width: 100px;
    height: 100%;

    object-fit: cover;
  }

  @include ifmobile {
    display: grid;
    grid-template-areas:
      "image info"
      "remove  remove";
    justify-content: center;
    align-items: center;
    grid-template-columns: auto 1fr;
  }
}

.imageWrapper {
  height: fit-content;

  @include ifmobile {
    grid-area: image;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $primaryBlack;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $primaryBlack;

    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  @include ifmobile {
    grid-area: info;
  }
}

.priceAndQuantityWrapper {
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  @include ifmobile {
    display: grid;
    align-items: normal;
    grid-template-areas:
      "price"
      "close";

    margin: 0;

    grid-area: remove;
  }
}

.pricesWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;

    color: $primaryBlack;
    text-wrap: nowrap;
  }

  @include ifmobile {
    margin-left: auto;
    grid-area: price;
    gap: 4px;

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: $redDark;
  opacity: 1;
  rotate: -20deg;
}

.pricesWrapper .oldPrice {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.removeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  color: $redDark;

  cursor: pointer;

  white-space: nowrap;

  @include ifmobile {
    grid-area: close;

    text-wrap: nowrap;

    margin-left: auto;
  }
}

.counterWrapper {
  display: flex;
  align-items: center;

  border: 1px solid $primaryBlack;
  border-radius: 4px;

  width: 70px;

  color: $primaryBlack;

  @include ifmobile {
    width: 85px;
  }
}

.marksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-left: 1px solid $primaryBlack;

  width: 100%;
}

.plus {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  width: 100%;

  border-bottom: 1px solid $primaryBlack;

  cursor: pointer;

  @include ifmobile {
    padding: 1px;

    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}

.minus {
  cursor: pointer;
  padding: 4px;

  @include ifmobile {
    padding: 1px;

    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}
