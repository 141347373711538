@use "../../../../../assets/scss/main.scss" as *;

.bookContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 240px;

  padding: 24px 16px;

  background: $transparent-black;

  cursor: pointer;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;

  height: 100%;
}

.imageWrapper {
  display: flex;
  align-self: center;
  justify-self: center;

  position: relative;

  img {
    width: 70px;
    height: 115px;

    object-fit: cover;
  }
}

.otherBooks {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: absolute;

  background: $black;

  opacity: 0.8;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    color: $white;
  }
}

.info {
  display: grid;
  gap: 5px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}

.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  margin-top: auto;
}

.pricesWrapper {
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    text-wrap: nowrap;
  }

  text-wrap: nowrap;
}
