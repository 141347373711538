@use "../../../../assets/scss/main.scss" as *;

.book {
  display: grid;
  grid-template-areas:
    "image title icon"
    "image description icon"
    "image readMore icon";
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: start;
  gap: 16px;

  padding: 16px;

  height: fit-content;

  background: $whiteGray;

  border-radius: 16px;

  box-shadow: $my-book-shadow;

  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  @include ifmobile {
    grid-template-areas:
      "image title icon"
      "description description description"
      "readMore readMore readMore";
    padding: 12px;
    gap: 12px;
  }
}

.collectionBook {
  border: none;

  .image {
    width: 67px;
    height: 109px;
    object-fit: cover;
    border-radius: 4px;

    @include ifmobile {
      width: 60px;
      height: 90px;
    }
  }
}

.imageWrapper {
  display: flex;
  align-items: center;

  grid-area: image;
}

.image {
  width: 67px;
  height: 109px;
  object-fit: cover;
  border-radius: 4px;

  @include ifmobile {
    width: 60px;
    height: 90px;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;

  color: $primaryBlack;

  margin-bottom: 8px;

  grid-area: title;

  @include iftablet {
    font-size: 32px;
  }

  @include ifmobile {
    font-size: 24px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;

  color: $primaryBlack;

  flex: 1;
  margin-bottom: 12px;

  grid-area: description;

  @include iftablet {
    font-size: 14px;
  }

  @include ifmobile {
    font-size: 12px;
  }
}

.readMore {
  font-size: 14px;
  color: $orange;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s ease;

  grid-area: readMore;

  &:hover {
    color: darken($orange, 10%);
  }

  @include iftablet {
    font-size: 12px;
  }
}

.downloadWrapper {
  grid-area: icon;
}

.downloadIcon {
  cursor: pointer;
}
