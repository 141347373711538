@use "../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.profileLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.mainWrapper {
  display: grid;
  grid-template-columns: 10% 90%;
  flex: 1;

  @include ifdesktopL {
    grid-template-columns: 18% 82%;
  }

  @include iftablet {
    grid-template-columns: 25% 75%;
  }

  @include ifmobile {
    grid-template-columns: 0 100%;
  }
}

.outletWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;

  padding: 74px 110px;

  @include ifmobile {
    padding: 20px 40px;
  }
}

.violetCircle {
  position: absolute;
  width: 100%;
  height: 100%;

  filter: blur(100px);

  border-radius: 30%;

  bottom: -10%;
  right: -60%;

  background: $violet-gradient;

  transform: rotate(10deg);

  z-index: -1;
}

.redCircle {
  position: absolute;
  width: 40%;
  height: 100%;

  filter: blur(50px);

  border-radius: 50%;

  top: 30%;
  right: -30%;

  background: $circle-red-dark;

  z-index: -1;
}
