@use "../../assets/scss/main.scss" as *;

.itemsCounterWrapper {
  display: flex;
  justify-self: flex-end;
  align-items: center;
  gap: 10px;

  padding: 4px 8px;

  border: 1px solid $white;
  border-radius: 8px;
}

.itemsCount {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item {
  padding: 2px;
  border-radius: 4px;

  cursor: pointer;
}

.activeItem {
  background: $white;
  color: $black;
}
