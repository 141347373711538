@use "../../assets/scss/main.scss" as *;

.modal {
  position: fixed;
  left: 50%;
  top: 50%;

  box-shadow: $teacher-notification-shadow;
  background-color: white;

  z-index: 999;

  max-width: 453px;
  min-width: 350px;

  transform: translate(-50%, -50%);
}

.header {
  display: flex;
  justify-content: space-between;

  padding: 12px 21px;

  background-color: $custom-blue;
}

.closeIcon {
  display: flex;

  cursor: pointer;
}

.circles {
  display: flex;
  gap: 8px;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &:first-child {
      background-color: $custom-red;
    }

    &:nth-child(2) {
      background-color: $custom-yellow;
    }

    &:nth-child(3) {
      background-color: $custom-green;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  background: $white;

  padding: 24px 80px;

  @include iftablet {
    padding: 12px 24px;
  }
}

.headerForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    text-align: center;

    color: $custom-secondary-black;

    @include ifmobile {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;

    color: $black;

    @include ifmobile {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.formBody {
  display: flex;
  flex-direction: column;
  gap: 24px;

  background: $white;

  @include iftablet {
    padding: 14px 40px 16px;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputGroup {
  display: flex;
  gap: 18px;

  @include iftablet {
    flex-direction: column;
    gap: 16px;
  }
}

.buttonWrapper {
  justify-self: center;

  margin-top: 20px;
}
