@use "../../assets/scss/main.scss" as *;

.aside {
  background: $black;

  padding: 80px 10%;
}

.linksWrapper {
  display: grid;
  gap: 8px;

  @include ifmobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: fixed;
    text-align: center;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    padding: 80px 0;
    background-color: $blackLight;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition-duration: 0.25s;
    z-index: 2;
  }
}

.link {
  color: rgba(255, 255, 255, 0.6);

  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;

  @include ifmobile {
    display: block;
    padding: 12px 24px;
    transition-duration: 0.25s;
  }
}

.activeLink {
  color: $whiteGray;
  font-weight: 500;

  font-family: Inter;
  font-size: 18px;
  line-height: 120%;

  @include ifmobile {
    display: block;
    padding: 12px 24px;
    transition-duration: 0.25s;
  }
}
