@use "../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;

  padding: 5%;

  a {
    color: $orange;
  }

  ul {
    padding-left: 24px;

    color: $custom-black;
  }

  ul > li {
    list-style: disc;

    margin-left: 24px;

    color: $custom-black;
  }

  ol > li {
    list-style: decimal;

    margin-left: 24px;

    color: $custom-black;
  }

  h4,
  h5,
  p {
    color: $custom-black;
  }
}

.header {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
}
