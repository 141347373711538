@use "../../../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  justify-content: space-between;
  gap: 3%;

  padding: 44px 78px;

  background: $custom-white;
  text-align: left;

  height: 100%;
  width: 100%;

  @include ifdesktopM {
    padding: 7% 5%;
  }

  @include ifmobile {
    grid-template-columns: auto;
    padding: 48px 16px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 21px;
}

.description {
  display: grid;
  gap: 8px;

  h2 {
    line-height: 58px;
    font-weight: 400;
    color: $primaryBlack;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    color: $primaryBlack;
  }

  @include iftablet {
    h2 {
      line-height: 40px;
    }
  }

  @include ifmobile {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }

    p {
      font-size: 12px;
      line-height: 26px;
    }
  }
}

.imageWrapper {
  box-shadow: $image-shadow;

  img {
    object-fit: cover;

    width: 182px;
    height: 100%;
  }

  @include ifmobile {
    order: -1;

    height: 380px;
  }
}
