@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;

  flex: 1;

  background: $white;
}

.booksWrapper {
  position: relative;

  padding: 60px 0 100px;

  overflow: hidden;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.orangeCircle {
  position: absolute;
  width: 80%;
  height: 30%;

  filter: blur(100px);

  border-radius: 50%;

  top: -10%;
  right: 10%;

  background: $circle-yellow;
}

.redCircle {
  position: absolute;
  width: 50%;
  height: 40%;

  filter: blur(100px);

  border-radius: 50%;

  top: 2%;
  left: -40%;

  background: $circle-red;
}

.redDownCircle {
  position: absolute;
  width: 50%;
  height: 40%;

  filter: blur(100px);

  border-radius: 50%;

  top: 50%;
  left: -40%;

  background: $circle-red;
}

.yellowCircle {
  position: absolute;
  width: 80%;
  height: 40%;

  filter: blur(100px);

  border-radius: 50%;

  top: 53%;
  right: 10%;

  background: $circle-yellow;
}

.title {
  position: relative;

  color: $primaryBlack;

  padding: 0 10%;
}
