@use "../../assets/scss/main.scss" as *;

.textarea-input-label {
  position: relative;
  box-sizing: border-box;
}

.textarea-input {
  display: block;
  background: $custom-white;
  color: $gray;

  margin: 0;
  resize: vertical;

  border-radius: 5px;

  width: 100%;
  box-sizing: border-box;

  appearance: none;

  &:focus {
    outline: 0;
  }
}

.textarea-input::placeholder {
  color: $black;

  font-size: 11px;
  font-weight: 300;
  line-height: 25px;

  opacity: 0.5;
}

.default {
  padding: 18px;

  border: none;
  border-radius: 0;

  color: $black;

  font-size: 11px;
  font-weight: 300;
  line-height: 25px;
}

.default:hover {
  box-shadow: inset 0px 0px 0px 2px $white;
}

.admin {
  padding: 13px 18px;

  background-color: transparent;
  color: $black;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  border-radius: 5px;
  border: 0;
  box-shadow: inset 0px 0px 0px 1px $gray;
}

.admin:is(:hover, :focus) {
  box-shadow: inset 0px 0px 0px 1px $grayDark;
}

.admin::placeholder {
  font-family: $font-main;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.label,
.withoutValue {
  position: absolute;
  top: 12px;
  left: 14px;

  pointer-events: none;

  background: $white;

  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: all 0.2s ease-out;
}

.inputWrapper:focus-within .label,
.input:-webkit-autofill + .label,
.withValue {
  top: -12px;
  left: 8px;
  padding: 0 8px;
  border-radius: 1px;
}

.text,
.withoutValue .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  transition: all 0.2s ease-out;

  color: $grayDark;
}

.inputWrapper:focus-within .text,
.input:-webkit-autofill + .label .text,
.withValue .text {
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease-out;
}

.label span {
  color: $white;
}
