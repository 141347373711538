@use "../../assets/scss/main.scss" as *;

.container {
  position: relative;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  margin: 48px 0 24px;
}

.arrow {
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  padding: 5px;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }
}

.paginationArrow {
  p {
    color: $primaryBlack;
  }
}

.adminPaginationArrow {
  p {
    color: $white;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 34px;
  height: 34px;

  border-radius: 2px;

  color: $primaryBlack;

  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.pageLink {
  color: $primaryBlack;
}

.adminPageLink {
  color: $white;
}

.activeLink {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.activeLinkClassName {
  background: $primaryBlack;

  color: $white;
}

.adminActiveLinkClassName {
  background: $white;

  color: $primaryBlack;
}

.disableArrow {
  .paginationArrow,
  .adminPaginationArrow {
    p {
      color: $grayLight;
    }
  }
}

.previousArrow {
  transform: rotate(180deg);
}

.paginationDescription {
  text-align: center;

  color: $grayLight;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
