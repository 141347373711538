@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.text {
  font-weight: 600;

  color: $primaryBlack;
}
