.section {
  display: grid;
  grid-template-columns: 0.5fr;
  align-items: center;
  justify-content: center;

  margin: 200px 0;
}

.form {
  display: grid;
  gap: 24px;
}

.buttonWrapper {
  justify-self: center;
}
