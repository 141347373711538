@use "../../../../assets/scss/main.scss" as *;

.discountSection {
  z-index: 1;

  padding: 0 12px;
}

.contentWrapper {
  background: $whiteGray;

  border-radius: 10px;

  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;

  max-width: 1064px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.discountInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  max-height: 259px;

  text-align: center;

  padding: 0 20px;

  p {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;

    color: $primaryBlack;

    word-break: normal;
  }

  h1 {
    font-size: 64;
    font-weight: 500;
    line-height: 77px;

    color: $primaryBlack;

    span {
      font-weight: 900;
    }
  }

  @include iftablet {
    gap: 12px;

    padding: 5%;

    h1 {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @include ifmobile {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.discountImage {
  display: flex;
  height: 100%;

  max-height: 309px;

  img {
    width: 100%;
    object-fit: cover;
  }
}
