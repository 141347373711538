@use "../../../assets/scss/main.scss" as *;

.section {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: center;

  color: $primaryBlack;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;

  color: $primaryBlack;
}
