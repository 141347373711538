@use "../../../../../assets/scss/main.scss" as *;

.bookContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 240px;

  padding: 24px 16px;

  background: $transparent-black;

  cursor: pointer;
}

.imageWrapper {
  display: flex;
  align-self: center;
  justify-self: center;
  height: 100%;

  img {
    width: 150px;
    height: 220px;
  }
}

.info {
  display: grid;
  gap: 5px;
}

.description {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pricesWrapper {
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    text-wrap: nowrap;
  }

  text-wrap: nowrap;
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;

  top: 50%;

  width: 100%;
  height: 2px;

  background: $red;

  rotate: -20deg;
}
