@use "../../assets/scss/main.scss" as *;

.modal {
  position: absolute;
  top: 15%;

  & :global {
    .panel-body {
      background: $custom-white;
    }
  }

  @include ifmobile {
    top: 5%;
  }

  z-index: 2;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  color: $whiteGray;

  opacity: 0.8;
}

.cartWrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @include iftablet {
    flex-direction: column;
  }
}

.booksWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
}

.emptyCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  text-align: center;

  h3,
  p {
    color: $primaryBlack;

    word-break: break-word;
  }
}
