@use "../../assets/scss/main.scss" as *;

.mainSection {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 64px;

  flex: 1;

  background: $black;

  padding: 32px 10%;

  @include ifmobile {
    gap: 32px;
  }
}
