@use "../../../../assets/scss/main.scss" as *;

.container {
  margin-bottom: 100px;

  z-index: 1;
}

.contentWrapper {
  display: grid;
  gap: 32px;

  text-align: center;

  h3 {
    font-weight: 600;
    line-height: 40px;
  }
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px;

  max-width: 1064px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.form {
  display: grid;

  width: 75%;
  margin: 0 auto;

  @include iftablet {
    width: 85%;
  }

  @include ifmobile {
    width: 100%;
  }
}

.formHeader {
  display: flex;
  gap: 5px;

  padding: 12px 22px 16px;

  background: $custom-blue;
}

.icon {
  width: 10px;
  height: 10px;

  border-radius: 50%;
}

.closeIcon {
  background-color: $custom-red;
}

.turnIcon {
  background-color: $custom-yellow;
}

.expandIcon {
  background-color: $custom-green;
}

.formBody {
  display: grid;

  padding: 28px 80px 42px;

  background: $white;

  @include iftablet {
    padding: 14px 40px 16px;
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  margin-bottom: 24px;

  @include iftablet {
    flex-direction: column;
    gap: 16px;
  }
}

.inputGroup {
  display: grid;
  gap: 16px;
  width: 100%;

  @include iftablet {
    gap: 16px;
  }
}

.buttonWrapper {
  justify-self: center;

  margin-top: 20px;
}
