@use "../../assets/scss/main.scss" as *;

.footer {
  background: $blackLight;
}

.contentWrapper {
  display: grid;
  align-items: center;
  gap: 100px;

  padding: 48px 12px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.navigationWrapper {
  display: flex;
  justify-content: space-between;

  @include iftablet {
    flex-direction: column;
    gap: 16px;
  }
}

.footerInfo {
  display: grid;
  row-gap: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
}

.visaImage {
  max-width: 355px;
  width: 100%;
}

.footerItemWrapper {
  display: flex;
  gap: 64px;

  text-align: end;

  @include iftablet {
    text-align: start;
  }

  @include ifmobile {
    flex-wrap: wrap;
    justify-content: start;
    gap: 40px;

    text-align: start;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;

  color: $whiteGray;

  margin-bottom: 20px;

  @include ifmobile {
    font-weight: 600;

    margin-bottom: 8px;
  }
}

.footerItems {
  display: grid;
  gap: 8px;

  @include ifmobile {
    gap: 0;
  }
}

.footerItem {
  display: grid;
  gap: 8px;

  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;

  @include iftablet {
    text-align: start;
  }

  @include ifmobile {
    flex-wrap: wrap;
  }
}

.footerTitle {
  padding: 8px 0 0;
  border-top: 1px solid $separator;

  text-align: center;

  p {
    color: $whiteGray;

    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
  }
}
