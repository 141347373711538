@use "../../assets/scss/main.scss" as *;

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  min-width: 66px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 16px;
  opacity: 1;
  transition: opacity 0.2s ease;
  white-space: nowrap;
}

.button:is(:hover, :active) {
  opacity: 0.8;
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button span {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  display: flex;
  align-items: center;
  gap: 8px;
}

.small {
  line-height: 28px;
  padding: 4px 24px;
  width: fit-content;
}

.small span {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 13px;
}

.medium {
  min-width: 66px;
}

.full {
  width: 100%;
}

.auto {
  width: auto;
}

.primary {
  background: $orange;
  color: $white;
}

.secondary {
  background-color: $white;
  color: $black;
}

.client {
  padding: 14px 44px;
}

.admin {
  padding: 4px 16px;
}

.default {
  color: $white;
}

.outline {
  background: transparent;

  padding: 0;

  span {
    color: $primaryBlack;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
  }
}

.avatar {
  padding: 4px 24px;

  background: $custom-black;

  span {
    color: $white;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
