@use "../../assets/scss/main.scss" as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include ifmobile {
    flex-direction: column;
    gap: 20px;
  }
}

.linksWrapper {
  display: flex;
  gap: 32px;
  height: fit-content;

  @include ifmobile {
    gap: 0;
  }
}

.link {
  color: rgba(255, 255, 255, 0.6);
  position: relative;

  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;

  @include ifmobile {
    display: block;
    padding: 12px 16px;
    transition-duration: 0.25s;
  }
}

.link:after {
  content: "";
  height: 1px;
  width: 0;
  background: $whiteGray;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.2s ease;
}

.activeLink {
  color: $whiteGray;
  font-weight: 500;

  font-family: Inter;
  font-size: 18px;
  line-height: 120%;
  position: relative;

  @include ifmobile {
    display: block;
    transition-duration: 0.25s;
  }
}

.link.activeLink:after {
  width: 100%;
}

.controlWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.button {
  text-transform: uppercase;
}
