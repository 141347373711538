@use "../../../../assets/scss/main.scss" as *;

.collection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;

  background: $whiteGray;

  border-radius: 16px;
  box-shadow: $my-book-shadow;

  height: fit-content;
}

.collectionTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;

  color: $primaryBlack;
}

.booksWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.book {
  display: flex;
  align-items: center;
  gap: 16px;
}

.imageWrapper {
  display: flex;
  align-items: center;
}

.image {
  width: 16px;
  height: 26px;
  object-fit: cover;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;

  width: 100%;

  color: $primaryBlack;
}

.downloadIcon {
  cursor: pointer;
}
