@use "../../../assets/scss/main.scss" as *;

.openBankingSection {
  position: relative;
  flex: 1 0 auto;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;

  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  padding: 82px 15px;

  max-width: $main-container-width;
  width: 100%;
  height: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 82px 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.openBankingLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
