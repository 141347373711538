@use "../../../../assets/scss/main.scss" as *;

.form {
  display: grid;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  gap: 16px;

  color: $primaryBlack;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;

  p {
    color: $primaryBlack;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}

.selectWrapper {
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;

  margin-top: 24px;

  @include ifmobile {
    button {
      padding: 14px 24px;
    }
  }
}

.cancelButton {
  padding: 14px 44px;

  border: 1px solid $primaryBlack;
}
