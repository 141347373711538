@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 40px;

  flex: 1 0 auto;

  background: $black;

  @include iftablet {
    gap: 100px;
  }

  @include ifmobile {
    gap: 32px;
  }
}
