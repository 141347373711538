@use "../../assets/scss/main.scss" as *;

.header {
  box-shadow: $header-shadow;

  z-index: 1;

  picture {
    display: flex;
  }

  img {
    cursor: pointer;
  }

  @include iftablet {
    gap: 4px;
    padding: 18px 52px;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 8px;
    padding: 18px 16px;

    img {
      width: 70px;
      height: 100px;
      object-fit: cover;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: 1324px;
  width: 100%;
  padding: 18px 12px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0;

    gap: 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.menuToggle {
  display: none;
}

.menuToggle:checked + .menuBtn > span {
  transform: rotate(45deg);

  background: $whiteGray;
}

.menuToggle:checked + .menuBtn > span::before {
  top: 0;
  transform: rotate(0deg);

  background: $whiteGray;
}

.menuToggle:checked + .menuBtn > span::after {
  top: 0;
  transform: rotate(90deg);

  background: $whiteGray;
}

.menuToggle:checked ~ .linksWrapper {
  left: 0;

  align-items: center;
}

.menuBtn {
  display: none;

  right: 0;

  transition-duration: 0.25s;

  @include iftablet {
    display: flex;
    position: absolute;

    width: 24px;

    top: 14px;
    right: 12px;

    cursor: pointer;

    z-index: 3;

    height: 24px;
  }
}

.menuBtn > span,
.menuBtn > span::before,
.menuBtn > span::after {
  display: block;
  position: absolute;
  width: 24px;
  height: 2px;
  background: $primaryBlack;
  transition-duration: 0.25s;
}

.menuBtn > span::before {
  content: "";
  top: -8px;
}

.menuBtn > span::after {
  content: "";
  top: 8px;
}

.mainWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @include ifdesktopM {
    align-items: flex-start;
  }

  @include iftablet {
    gap: 12px;

    position: relative;
  }

  @include ifmobile {
    justify-content: space-between;
  }
}

.linksWrapper {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  position: relative;
  gap: 16px;

  @include iftablet {
    flex-direction: column;
    gap: 0;

    position: fixed;

    margin-right: 0;

    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 80px 24px 24px;

    background: $hard-black;

    transition-duration: 0.25s;
    z-index: 2;
  }
}

.clientHeader {
  background: $white;

  z-index: 4;
}

.adminHeader {
  background: $white;

  z-index: 4;
}

.logoAndNavigationWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
}

.logo {
  display: flex;

  cursor: pointer;
}

.navigation {
  display: flex;
  gap: 16px;

  text-wrap: nowrap;

  font-family: $font-main;

  @include ifdesktopM {
    gap: 8px;

    width: 100%;
  }

  @include iftablet {
    flex-direction: column;
    align-items: center;

    gap: 16px;

    text-align: center;

    padding-bottom: 32px;

    border-bottom: 1px solid $transparent-white-gray;
  }
}

.link {
  text-wrap: nowrap;
  color: $primaryBlack;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @include ifdesktopM {
    font-size: 18px;
    font-weight: 500;

    display: block;
    transition-duration: 0.25s;
  }

  &:is(:hover, :active) {
    color: $grayLight;
  }

  @include iftablet {
    color: $whiteGray;

    width: fit-content;
  }
}

.inputWrapper {
  position: relative;

  @include ifmobile {
    order: 1;
  }
}

.dropDownItems {
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: absolute;

  width: 100%;

  border-radius: 16px;

  box-shadow: $order-shadow;
  background: $white;

  padding: 20px;

  z-index: 1;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemsTitle {
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  text-decoration: underline;

  color: $primaryBlack;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  width: fit-content;

  color: $primaryBlack;

  cursor: pointer;

  &:is(:hover, :active) {
    font-weight: 600;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 10px;

  button {
    width: auto;
  }

  @include iftablet {
    width: 100%;
    flex-direction: column;
    align-self: flex-end;

    button {
      width: 100%;
    }

    span {
      color: $whiteGray;
    }
  }
}

.currLangWrapper {
  display: flex;
  gap: 24px;

  .currencySelector {
    margin-left: auto;

    @include ifmobile {
      margin: 0;
    }
  }

  @include ifdesktopM {
    flex: 1;
  }

  @include iftablet {
    flex-direction: column;
    gap: 8px;

    flex: 0;

    padding: 32px 0;
  }
}

.userWrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  @include ifdesktopM {
    margin-left: auto;
  }

  @include iftablet {
    flex: 1;

    width: 100%;
    padding-top: 32px;

    border-top: 1px solid $transparent-white-gray;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 24px;

  @include ifdesktopM {
    gap: 12px;
    width: 100%;
    justify-content: space-between;
  }

  @include iftablet {
    justify-content: center;
    align-self: flex-start;
  }
}

.pictureWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  img {
    border-radius: 50%;

    background: $black;

    width: 50px;
    height: 50px;

    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  p {
    color: $black;

    font-size: 16px;
    font-weight: 600;
  }

  &:is(:hover, :active) img {
    opacity: 0.6;
  }

  @include iftablet {
    p {
      color: $whiteGray;
    }
  }
}

.arrowIcon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.arrowIcon.activeIcon {
  transform: rotate(-180deg);
  transition: transform 0.3s linear;
}

.dropDownMenu {
  position: absolute;
  top: 55px;

  border-radius: 4%;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5%;

  text-align: center;

  width: fit-content;

  background: $black;
  z-index: 1;

  .link {
    color: rgba(255, 255, 255, 0.6);

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    padding: 6px 24px;

    @include ifdesktopM {
      font-size: 14px;
      font-weight: 500;

      display: block;
      padding: 6px 12px;
      transition-duration: 0.25s;
    }

    &:is(:hover, :active) {
      color: $white;
    }
  }

  @include iftablet {
    align-items: center;

    background: $whiteGray;

    bottom: 55px;
    top: auto;

    width: 250px;

    left: -50%;

    transform: translateX(10%);

    .link {
      color: $black;

      &:is(:hover, :active) {
        color: $secondary-gray;
      }
    }
  }
}

.cartIcon {
  display: flex;

  position: relative;

  cursor: pointer;

  @include iftablet {
    display: none;
  }
}

.mobileCartIcon {
  display: none;

  @include iftablet {
    display: flex;

    z-index: 3;

    margin-right: 48px;
  }
}

.isOpenedCart path {
  fill: $whiteGray;
}

.selectWrapper {
  color: $primaryBlack;
}

.cartAmount {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  right: -6px;
  top: -4px;

  width: 16px;
  height: 16px;

  border-radius: 50%;

  background: $orange;

  p {
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
  }
}
