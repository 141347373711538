@use "../../../../assets/scss/main.scss" as *;

.section {
  z-index: 1;
}

.contentWrapper {
  display: flex;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px;

  max-width: 1064px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.carouselWrapper {
  z-index: 0;

  background: $custom-white;

  border-radius: 14px;
  overflow: hidden;

  & :global {
    .carousel {
      .control-dots {
        left: 0;

        .dot {
          width: 9px;
          height: 9px;

          &.selected {
            &::after {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
