@use "../../assets/scss/main.scss" as *;

.modal {
  position: absolute;
  top: 55px;
  right: 5%;

  z-index: 100;
}

.container {
  display: grid;
  gap: 20px;
}

.totalWrapper {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $primaryBlack;
  }
}

.emptyCartText {
  text-align: center;

  color: $primaryBlack;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 14px 50px;
  }
  
  @include ifmobile {
    justify-content: center;
    button {
      padding: 14px 20px;
    }
  }
}
