@use "../assets/scss/main.scss" as *;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font-main;
}

html,
body {
  max-width: 100vw;

  scrollbar-gutter: stable;
  -webkit-scrollbar-gutter: stable;
}

ul,
li {
  list-style: none;
}

a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

button {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  border: none;
  color: inherit;
  font-size: inherit;
}

p {
  color: $whiteGray;
}

h1 {
  font-size: 64px;

  @include ifdesktopM {
    font-size: 56px;
  }

  @include iftablet {
    font-size: 48px;
  }
}

h2 {
  font-size: 48px;

  @include ifdesktopM {
    font-size: 36px;
  }

  @include iftablet {
    font-size: 32px;
  }

  @include ifmobile {
    font-size: 28px;
  }
}

h3 {
  font-size: 36px;

  @include ifdesktopM {
    font-size: 28px;
  }

  @include iftablet {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;

  @include ifdesktopM {
    font-size: 20px;
  }

  @include iftablet {
    font-size: 18px;
  }
}

h5 {
  font-size: 20px;

  @include ifdesktopM {
    font-size: 18px;
  }

  @include iftablet {
    font-size: 16px;
  }
}

h6 {
  font-size: 18px;

  @include ifdesktopM {
    font-size: 16px;
  }

  @include iftablet {
    font-size: 14px;
  }
}
