@use "../../assets/scss/main.scss" as *;

.notificationSection {
  display: flex;
  justify-content: flex-end;

  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px;

  max-width: 1064px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    justify-content: center;

    width: 343px;
    max-width: 500px;
  }
}

.container {
  box-shadow: $teacher-notification-shadow;

  background-color: $white;

  position: fixed;
  bottom: 40px;

  align-self: flex-end;
  justify-self: center;

  right: 120px;

  max-width: 396px;

  z-index: 3;

  animation: scaleAnimation 2s ease-in-out infinite alternate;

  @include iftablet {
    right: 30px;
  }

  @include ifmobile {
    right: 15px;
    left: 15px;
  }

  .top {
    display: flex;
    justify-content: space-between;

    padding: 12px 21px;

    background-color: $custom-blue;
  }

  .closeIcon {
    display: flex;

    cursor: pointer;
  }

  .circles {
    display: flex;
    gap: 8px;

    div {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &:first-child {
        background-color: #ff6057;
      }

      &:nth-child(2) {
        background-color: #ffc12e;
      }

      &:nth-child(3) {
        background-color: #28ca40;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 24px 40px;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      text-align: center;

      color: $custom-secondary-black;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include ifmobile {
    flex-direction: column;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
