@use "../../assets/scss/main.scss" as *;

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
}

.phoneInput {
  width: 100%;
  padding: 13px 18px;

  box-shadow: inset 0px 0px 0px 1px $gray;

  color: $black;

  background: transparent;

  border: 0;
  border-radius: 5px;

  input {
    width: 100%;
    background: transparent;
    border: 0;

    transition: border-width 0.2s linear;
    transition: 0.2s;
    outline: none;

    color: $black;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.phoneInput:is(:hover, :focus) {
  box-shadow: inset 0px 0px 0px 1px $grayDark;
}

.input {
  width: 100%;

  background: transparent;

  border: 0;
  border-radius: 5px;

  transition: border-width 0.2s linear;
  transition: 0.2s;
  outline: none;
}

.input::placeholder {
  color: $grayDark;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.default {
  padding: 13px 18px;
  box-shadow: inset 0px 0px 0px 1px $gray;

  color: $black;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.default:is(:hover, :focus) {
  box-shadow: inset 0px 0px 0px 1px $grayDark;
}

.secondary {
  background: $custom-white;

  padding: 4px 18px;
  border-radius: 0;

  color: $black;
  font-size: 11px;
  font-weight: 300;
  line-height: 25px;
}

.secondary:is(:hover, :focus) {
  box-shadow: inset 0px 0px 0px 1px $grayDark;
}

.secondary::placeholder {
  color: $black;
  opacity: 0.5;

  font-family: $font-main;
  font-size: 11px;
  font-weight: 300;
  line-height: 25px;
}

.search {
  padding: 13px 18px 13px 52px;
  box-shadow: inset 0px 0px 0px 1px $gray;

  color: $grayLight;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.search:is(:hover, :focus) {
  box-shadow: inset 0px 0px 0px 1px $grayDark;
}

.footer {
  padding: 10px 14px;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 1px $custom-gray;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.footer::placeholder {
  color: $custom-gray;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.admin {
  padding: 10px 14px;

  max-height: 44px;

  color: $whiteGray;

  font-family: $font-main;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 10px;
  border: 1px solid rgba(232, 232, 232, 0.3);
  box-shadow: none;
}

.admin:is(:hover, :focus) {
  border: 1px solid $whiteGray;
}

.admin::placeholder {
  font-family: $font-main;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

input[type="radio"],
input[type="radio"]:hover {
  box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:disabled {
  opacity: 0.4;
  box-shadow: inset 0px 0px 0px 1px $white;
  cursor: not-allowed;
}

.input:disabled + .label {
  opacity: 0.8;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
  -webkit-text-fill-color: $black;
}

.input[type="date"] {
  line-height: normal;
}

.input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(98%) sepia(87%) saturate(100%) hue-rotate(303deg)
    brightness(117%) contrast(90%);
  padding-left: 50%;
  width: 16px;
  height: 16px;
}

.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  max-height: 44px;
  width: 100%;
}

.startIcon,
.endIcon {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.startIcon {
  inset-inline-start: 16px;

  margin-right: 16px;
}

.endIcon {
  inset-inline-end: 18px;
}

.label,
.withoutValue {
  position: absolute;
  top: 12px;
  left: 14px;

  pointer-events: none;

  background: $white;

  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: all 0.2s ease-out;
}

.inputWrapper:focus-within .label,
.input:-webkit-autofill + .label,
.withValue {
  top: -12px;
  left: 8px;
  padding: 0 8px;
  border-radius: 1px;
}

.text,
.withoutValue .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  transition: all 0.2s ease-out;

  color: $grayDark;
}

.inputWrapper:focus-within .text,
.input:-webkit-autofill + .label .text,
.withValue .text {
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s ease-out;
}

.label span {
  color: $white;
}

.tip {
  color: $gray;
}

.error {
  box-shadow: inset 0px 0px 0px 2px $red;
}

.error:hover,
.error:focus {
  box-shadow: inset 0px 0px 0px 2px $red;
}

.errorText {
  color: $red;
}

.errorAsterisk {
  color: $red;
}

.withEndIcon {
  padding-inline-end: 40px;
}

.withStartIcon {
  padding-inline-start: 48px;
}

.pointer {
  cursor: pointer;
}
