@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  grid-template-columns: 450px 4fr;

  background: $white;

  height: 100vh;

  @include ifmobile {
    grid-template-columns: auto;

    height: auto;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 200px;

  position: relative;
  overflow: hidden;

  background: $black;

  padding: 280px 48px 140px;

  @include ifmobile {
    display: none;
  }
}

.violetCircle {
  position: absolute;
  width: 200%;
  height: 37%;

  filter: blur(100px);

  border-radius: 30%;

  top: 7%;
  right: -120%;

  background: $violet-gradient;

  transform: rotate(-15deg);
}

.bigRedCircle {
  position: absolute;
  width: 100%;
  height: 50%;

  filter: blur(100px);

  border-radius: 50%;

  bottom: -25%;
  right: -50%;

  background: $circle-red;

  transform: rotate(10deg);
}

.logoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  z-index: 1;
}

.asideTitle {
  font-family: $font-kaushan;
  font-size: 68px;
  font-weight: 400;
  line-height: 99px;

  z-index: 1;
}

.info {
  display: grid;
  gap: 52px;

  z-index: 1;
}

.description {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
}

.author {
  font-weight: 400;
  line-height: 29px;
}

.form {
  background: $white;

  align-self: center;

  padding: 0 35%;

  @include ifdesktopM {
    padding: 0 25%;
  }

  @include iftablet {
    padding: 0 10%;
  }

  @include ifmobile {
    padding: 5% 15%;
  }
}

.formLogoTitle {
  font-family: $font-kaushan;
  font-size: 48px;
  font-weight: 400;
  line-height: 64px;

  color: $black;
}

.titleWrapper {
  display: grid;
  gap: 14px;

  text-align: center;

  margin-bottom: 35px;
}

.title {
  color: $primaryBlack;

  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.notification {
  color: $secondary-gray;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.inputs {
  display: grid;
  gap: 16px;
}

.inputWrapper {
  display: grid;
  gap: 9px;

  & > p {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;

    color: $secondary-black;
  }
}

.avatarWrapper {
  display: grid;
  gap: 8px;

  p {
    color: $primaryBlack;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.pictureWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  img {
    width: 100px;
  }
}

.deleteIcon {
  cursor: pointer;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxText {
  color: $primaryBlack;

  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  a {
    text-decoration: underline;
  }
}

.question {
  text-align: center;
  color: $primaryBlack;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  margin-top: 45px;

  a {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
  }
}
