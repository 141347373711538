@use "../../../assets/scss/main.scss" as *;

.formWrapper {
  display: grid;
  gap: 24px;

  width: 50%;

  @include iftablet {
    width: 100%;
  }
}

.description {
  display: grid;
  gap: 8px;

  p {
    color: $primaryBlack;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}

.inputWrapper {
  display: flex;
  gap: 24px;

  @include ifmobile {
    flex-direction: column;
  }
}

.fileWrapper {
  display: grid;
  gap: 8px;

  & > p {
    color: $whiteGray;

    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }
}

.pictureWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 100px;
  }
}

.deleteIcon {
  cursor: pointer;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $whiteGray;

    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    opacity: 0.7;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $whiteGray;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
}

.timezone {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.profileFooter {
  justify-self: center;

  margin-top: 24px;
}
