@use "../../../assets/scss/main.scss" as *;

.section {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  padding: 80px 0;

  @include iftablet {
    padding: 40px 0;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: center;

  color: $primaryBlack;
}

.referenceWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  text-align: start;
}

.referenceText {
  font-size: 14px;
  font-weight: 400;
  color: $primaryBlack;
}

.referenceTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listItem {
  list-style: disc;

  margin-left: 18px;

  color: $primaryBlack;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;

  color: $primaryBlack;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mainButton {
  background: none;

  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;

    color: $orange;
  }
}
