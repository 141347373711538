@use "../../../assets/scss/main.scss" as *;

.transactionSection {
  position: relative;

  height: 100%;

  background: $whiteGray;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;
  height: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.text {
  font-size: 32px;
  font-weight: 600;
  line-height: 35.2px;

  color: $primary-blue;
}
