@use "../../../../assets/scss/main.scss" as *;

.circleWrapper {
  position: relative;

  display: grid;
  gap: 90px;

  overflow: hidden;

  z-index: 0;
}

.whiteCircle {
  position: absolute;
  width: 75%;
  height: 15%;

  filter: blur(100px);

  border-radius: 70%;

  opacity: 0.7;

  top: -15%;
  right: -20%;

  background: $white;

  transform: rotate(-20deg);
  z-index: 1;
}

.violetCircle {
  position: absolute;
  width: 100%;
  height: 37%;

  filter: blur(100px);

  border-radius: 30%;

  top: -15%;
  right: -20%;

  background: $violet-gradient;

  transform: rotate(10deg);
}

.redCircle {
  position: absolute;
  width: 30%;
  height: 10%;

  filter: blur(100px);

  border-radius: 30%;

  top: 8%;
  left: 45%;

  background: $circle-pink;
}

.bigRedCircle {
  position: absolute;
  width: 40%;
  height: 25%;

  filter: blur(140px);

  border-radius: 50%;

  top: 20%;
  left: 15%;

  background: $circle-red;

  transform: rotate(10deg);
}

.orangeCircle {
  position: absolute;
  width: 80%;
  height: 30%;

  filter: blur(100px);

  border-radius: 50%;

  top: 8%;
  right: -30%;

  background: $circle-orange;

  transform: rotate(10deg);
}

.yellowCircle {
  position: absolute;
  width: 60%;
  height: 25%;

  filter: blur(100px);

  border-radius: 50%;

  top: 8%;
  right: -30%;

  background: $circle-yellow;

  transform: rotate(10deg);
}

.whiteDownCircle {
  position: absolute;
  width: 75%;
  height: 15%;

  filter: blur(100px);

  border-radius: 70%;

  bottom: 23%;
  right: -20%;

  background: $white;

  transform: rotate(-20deg);
}

.violetDownCircle {
  position: absolute;
  width: 100%;
  height: 14%;

  filter: blur(50px);

  border-radius: 30%;

  bottom: 25%;
  right: 0;

  background: $violet-gradient;

  transform: rotate(-25deg);
}

.redDownCircle {
  position: absolute;
  width: 30%;
  height: 10%;

  filter: blur(100px);

  border-radius: 30%;

  bottom: 15%;
  left: 45%;

  background: $circle-pink;
}

.bigRedDownCircle {
  position: absolute;
  width: 40%;
  height: 25%;

  filter: blur(140px);

  border-radius: 50%;

  bottom: -5%;
  left: 15%;

  background: $circle-red;

  transform: rotate(10deg);
}

.orangeDownCircle {
  position: absolute;
  width: 80%;
  height: 30%;

  filter: blur(100px);

  border-radius: 50%;

  bottom: -5%;
  right: -30%;

  background: $circle-orange;

  transform: rotate(10deg);
}

.yellowDownCircle {
  position: absolute;
  width: 60%;
  height: 25%;

  filter: blur(100px);

  border-radius: 50%;

  bottom: -5%;
  right: -30%;

  background: $circle-yellow;
  transform: rotate(10deg);
}
