.mainSection {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
}

.backgroundArrowImage {
  position: absolute;
  bottom: 20%;
  right: 0;
  width: 100%;
}
