@use "../../../../assets/scss/main.scss" as *;

.container {
  z-index: 0;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 35px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  padding: 0 12px;

  max-width: 1324px;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    width: 343px;
    max-width: 500px;
  }
}

.title {
  color: $primaryBlack;

  z-index: 0;
}

.mainTitle {
  margin-top: 2%;
}

.booksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
  column-gap: 24px;
}

.mainBooksWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 24px;
  column-gap: 24px;

  z-index: 0;
}

.buttonWrapper {
  align-self: center;
}
