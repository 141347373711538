@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 32px 0;

  height: 100%;
}

.tableWrapper {
  display: grid;
  position: relative;

  @include ifmobile {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
  }
}

.tableRowHead {
  border-radius: 14px;
  background: $white;

  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  color: $primaryBlack;
}

.tableRowBody {
  font-size: 16px;
  text-decoration: none;

  color: $whiteGray;

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  img {
    width: 50px;
    height: 75px;
  }

  & > div {
    padding: 0 4px;
  }
}

.tableRowHead,
.tableRowBody {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
  align-items: center;

  padding: 8px 16px;

  min-width: 500px;

  border-bottom: 1px solid $white;
  transition: background-color 0.3s ease;

  @include ifmobile {
    width: fit-content;
    grid-template-columns: 50px 50px 100px 50px 50px 50px 100px 50px 25px 25px;
    gap: 20px;
  }
}

.imageWrapper {
  display: flex;
}

.icon {
  display: flex;
  width: fit-content;

  cursor: pointer;
}
