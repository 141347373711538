@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;
}

.title {
  color: $primaryBlack;
}

.tableWrapper {
  display: grid;
  position: relative;
  overflow-x: auto;

  @include ifmobile {
    display: flex;
    flex-direction: column;
  }
}

.tableRowHead {
  border-radius: 14px;
  background: $primaryBlack;

  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  color: $white;

  white-space: nowrap;
}

.tableRow {
  display: flex;
  flex-direction: column;

  width: fit-content;

  border-bottom: 2px solid $primaryBlack;
}

.tableRowBody {
  font-size: 16px;
  text-decoration: none;

  color: $primaryBlack;

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  border-bottom: 2px solid $primaryBlack;

  .books {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  div {
    padding: 0 4px;
  }

  p {
    color: $primaryBlack;
  }
}

.tableRowHead,
.tableRowBody {
  display: grid;
  grid-template-columns: 260px 100px 100px 150px 75px 75px;
  gap: 20px;
  align-items: center;

  width: fit-content;

  padding: 8px 16px;

  min-width: 500px;

  border-bottom: 1px solid $white;
  transition: background-color 0.3s ease;

  @include ifmobile {
    width: fit-content;
    grid-template-columns: 150px 100px 100px 100px 60px 60px;
    gap: 20px;
  }
}

.imageWrapper {
  display: flex;
}

.downloadIcon {
  margin: 0 auto;

  cursor: pointer;
}
