@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  flex: 1;
  gap: 16px;

  background: $white;

  h3 {
    color: $primaryBlack;
  }
}

.circleWrapper {
  position: relative;

  overflow: hidden;

  padding: 24px 10% 100px;
}

.spinner {
  align-self: center;
  justify-self: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;

    color: $orange;

    cursor: pointer;
  }
}

.bookWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 32px;

  @include ifmobile {
    grid-template-columns: 1fr;
  }
}

.bookMain {
  display: grid;
  gap: 36px;
}

.bookInfoWrapper {
  display: flex;
  gap: 24px;
}

.imagesWrapper {
  display: grid;
  gap: 16px;

  img {
    width: 180px;
    height: 240px;
  }
}

.selectedImage {
  display: flex;
}

.imagesListWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-width: 220px;

  img {
    max-width: 35px;
    height: 60px;

    object-fit: cover;

    cursor: pointer;
  }
}

.pictureWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  position: relative;

  height: 68px;
}

.otherBooks {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 60px;

  position: absolute;

  background: $black;

  opacity: 0.8;

  cursor: pointer;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    color: $white;
  }
}

.pictureDivider {
  height: 3px;
  width: 100%;

  background: $orange;
}

.bookTypeWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bookType {
  display: grid;
  gap: 4px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    color: $primaryBlack;
  }

  .bookTypeText {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;

    color: $grayLight;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background: $separator;
}

.description {
  display: grid;
  gap: 16px;

  h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $primaryBlack;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $grayLight;
  }

  .booksTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;

    color: $primaryBlack;
  }

  ul,
  li {
    list-style: numeric;

    margin-left: 8px;
  }
}

.bookTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;

  color: $primaryBlack;

  text-decoration: underline;

  cursor: pointer;

  width: fit-content;
}

.violetCircle {
  position: absolute;
  width: 100%;
  height: 60%;

  filter: blur(100px);

  border-radius: 50%;

  top: 0%;
  right: -60%;

  background: $violet-gradient;

  transform: rotate(5deg);
}

.redCircle {
  position: absolute;
  width: 40%;
  height: 100%;

  filter: blur(50px);

  border-radius: 50%;

  top: 30%;
  right: -30%;

  background: $circle-red-dark;
}

.redDownCircle {
  position: absolute;

  width: 30%;
  height: 80%;

  filter: blur(150px);

  border-radius: 50%;

  top: 30%;
  right: -20%;

  background: $circle-pink;
}
