@use "../../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;

  background: $custom-white;
  width: fit-content;
  height: fit-content;

  padding: 16px;
  border-radius: 16px;

  z-index: 0;

  @include ifmobile {
    width: 100%;
  }
}

.priceWrapper {
  display: grid;
  gap: 8px;
}

.newPriceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;

    color: $primaryBlack;
  }
  .newPrice {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
  }
}

.oldPriceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;

    opacity: 0.8;

    color: $primaryBlack;
  }

  .oldPrice {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;

    opacity: 0.6;
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.5;
  rotate: -20deg;
}
