@use "../../../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  gap: 44px;

  margin: 0 1px 50px;

  @include ifmobile {
    grid-template-columns: 1fr;

    margin: 0 0 20px;
  }
}

.itemInfo {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 48px;

  margin-left: 108px;

  text-align: left;

  @include ifdesktopM {
    margin-left: 0;
  }

  @include iftablet {
    gap: 16px;
  }
}

.title {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;

  color: $whiteGray;

  span {
    font-family: $font-kaushan;
    font-weight: 400;
  }

  img {
    width: 50%;
  }

  @include iftablet {
    line-height: 40px;
  }

  @include ifmobile {
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }
}

.imageWrapper {
  @include ifmobile {
    padding-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
