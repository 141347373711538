@use "../../../../assets/scss/main.scss" as *;

.form {
  display: grid;
  gap: 16px;
}

.fileWrapper {
  display: flex;
  justify-content: space-between;

  color: $primaryBlack;
  font-size: 14px;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.pictureFileWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 12px;
    color: $primaryBlack;
  }
}

.pictures {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.pictureWrapper {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 150px;
    height: 136px;

    object-fit: cover;
  }
  &:is(:hover, :active) .deleteUploadingPicture {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.deleteUploadingPicture {
  position: absolute;
  height: 100%;
  width: 100%;

  display: none;

  background-color: $blackLight;
  opacity: 0.6;
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;

  p {
    color: $white;
  }
}

.defaultPicture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 150px;
  height: 136px;

  text-align: center;
  word-break: normal;

  cursor: pointer;

  padding: 16px;

  border: 1px dashed $grayLight;

  p {
    font-size: 12px;
    color: $primaryBlack;
  }
}

.acceptedPicture {
  border: 1px solid $green;

  p {
    color: $green;
  }
}

.rejectedPicture {
  border: 1px solid $red;

  p {
    color: $red;
  }
}

.productFileWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.uploadProgressBarWrapper {
  position: relative;
  width: 100%;
  height: 15px;

  background-color: $grayDark;

  border-radius: 20px;

  &:is(:hover, :active) .deleteUploadingFile {
    display: flex;

    position: absolute;
    top: 0;

    z-index: 1;

    opacity: 0.8;
  }
}

.uploadedTitle {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 1;

  p {
    font-size: 12px;
  }
}

.uploadProgressBar,
.deleteUploadingFile {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  height: 100%;
  width: 100%;

  overflow: hidden;

  color: $white;
  font-size: 10px;

  border-radius: 20px;
  background-color: $orange;
}

.deleteUploadingFile {
  display: none;

  background-color: $blackLight;
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
}

.uploadProgressBar:is(:hover, :active) .deleteUploadingFile {
  display: block;

  background-color: $blackLight;
  cursor: pointer;
}

.inputWrapper {
  display: flex;
  gap: 16px;

  color: $primaryBlack;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;

  p {
    color: $primaryBlack;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}

.selectWrapper {
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;

  margin-top: 24px;

  @include ifmobile {
    button {
      padding: 14px 24px;
    }
  }
}

.cancelButton {
  padding: 14px 44px;

  border: 1px solid $primaryBlack;
}
