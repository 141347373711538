@use "../../../assets/scss/main.scss" as *;

.orderWrapper {
  display: grid;
  gap: 24px;

  padding: 20px;
  border-radius: 16px;

  height: fit-content;

  box-shadow: $order-shadow;
  h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $primaryBlack;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;

  word-break: keep-all;

  color: $grayLight;

  a {
    font-weight: 500;

    text-decoration: underline;
  }
}

.infoWrapper {
  display: grid;
  gap: 20px;
}

.info {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $grayLight;
  }
}

.couponWrapper {
  display: flex;
  gap: 8px;
}

.couponButton {
  background: $custom-black;

  padding: 5.5px 8px;

  width: 118px;

  opacity: 0.8;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    width: 82px;

    white-space: wrap;

    word-break: keep-all;
  }
}

.total {
  display: flex;
  gap: 12px;
  justify-content: space-between;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $primaryBlack;

    white-space: nowrap;
  }
}

.separator {
  height: 1px;
  background: $separator;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    padding: 14px 16px;
  }
}
