// fonts:

// Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap");

// default colors
$white: #ffffff;
$whiteDark: #e8e8e8;
$whiteGray: #fdfdfd;
$black: #000000;
$dark: #1d1d1d;
$blackLight: #212121;
$green: #83d17e;
$greenLight: #346654;
$gray: #dadada;
$grayDark: #9b9ea6;
$grayMedium: #f4f4f4;
$grayLight: #858585;
$red: #ef4343;
$redDark: #a21916;
$orange: #f2631b;
$violet: rgba(137, 0, 201, 0.6);

// custom colors
$primary: #05422c;
$primary-blue: #003856;
$primaryBlack: #111726;
$neutral: #1a1e26;
$separator: #d9d9d980;
$violet-light: rgba(255, 204, 255, 0.6);
$custom-gray: #f1f1f199;
$custom-black: #010002;
$custom-secondary-black: #333333;
$custom-white: #f6f6f6;
$custom-blue: #dde4ec;
$custom-red: #ff6057;
$custom-yellow: #ffc12e;
$custom-green: #28ca40;
$custom-transparent-white: #e5eaee;
$custom-transparent-gray: #0f0f0f1a;
$custom-secondary-green: #34a853;
$transparent-white-gray: #fdfdfd33;
$transparent-green: #1e900c4d;
$transparent-black: #00000066;
$medium-black: #00000026;
$light-black: #00000040;
$secondary-black: #181a1e;
$secondary-gray: #636364;
$secondary-white: #d8d8d8;
$hard-black: #0f0f0f;
$header-black: #0000000d;
$notification-black: #00000033;

// table
$divider: rgba(0, 0, 0, 0.1);

// circles
$circle-red: #ff4473;
$circle-red-dark: #ff4473b2;
$circle-pink: #ff29a8;
$circle-yellow: #ffbb00;
$circle-orange: #ff3d00;

// shadows
$header-shadow: 0px 4px 4px 0px $header-black;
$checkbox-shadow: 0px 0px 0px 1px $red;
$order-shadow: 0px 0px 15px 0px $medium-black;
$image-shadow: 0px 0px 25px 0px $light-black;
$teacher-notification-shadow: 0px 2px 10px 0px $notification-black;
$bank-shadow: 0px 2px 6px 0px $custom-transparent-gray;
$my-book-shadow: 0px 0px 4px 0px $secondary-white;

// gradients
$violet-gradient: linear-gradient(
  82.36deg,
  $violet 38.7%,
  $violet-light 76.51%
);

// fonts
$font-main: "Inter", sans-serif;
$font-kaushan: "Kaushan Script", cursive;

// widths
$main-container-width: 1600px;
$tablet-container-width: 1024px;
$mobile-container-width: 768px;
