@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 16px;

  width: 100%;
}

.title {
  font-size: 36px;
  font-weight: 600;
  line-height: 50.4px;

  color: $primaryBlack;
}

.collectionsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  column-gap: 46px;
  row-gap: 24px;

  @include ifdesktopM {
    grid-template-columns: auto;
  }
}

.empty {
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;

  color: $primaryBlack;
}
