@use "../../../assets/scss/main.scss" as *;

.collection {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  padding: 10px 0;

  border-bottom: 1px solid $separator;
}

.collectionInfo {
  display: flex;
  gap: 10px;

  img {
    width: 32px;
    height: 52px;

    object-fit: cover;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    word-break: break-word;

    color: $grayLight;
  }
}

.collectionImageWrapper {
  display: flex;

  position: relative;
}

.leftBooks {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: $transparent-black;

  position: absolute;

  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.pricesWrapper {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $grayLight;
  }

  text-wrap: nowrap;
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.5;
  rotate: -20deg;
}
