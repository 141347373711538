@use "../../../../assets/scss/main.scss" as *;

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  position: relative;

  width: 564px;

  padding: 41.5px 60px;

  border-radius: 15px;

  background: $blackLight;
}

.formHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding: 12px 0;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;
}

.navigationText {
  font-size: 14px;
  font-weight: 700;
  line-height: 17.5px;

  text-transform: uppercase;

  color: $whiteGray;
}

.bankInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  img {
    width: 16px;
    height: 16px;

    object-fit: cover;
  }
}

.bankTitle {
  font-size: 10.66px;
  font-weight: 400;
  line-height: 13.32px;

  color: $whiteGray;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
}

.formInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;

  color: $whiteGray;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;

  opacity: 0.6;

  color: $whiteGray;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.safeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  width: fit-content;
  height: fit-content;

  padding: 2px 8px;

  border-radius: 4px;

  background: $transparent-green;
}

.safeText {
  font-size: 10px;
  font-weight: 400;
  line-height: 12.5px;

  color: $custom-secondary-green;

  opacity: 0.8;
}

.psuWrapper {
  .input {
    width: 100%;
  }

  .select {
    width: 100%;
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
  -webkit-text-fill-color: $whiteGray;
}
