@use "../../assets/scss/main.scss" as *;

:global {
  .pure-modal-backdrop {
    background-color: rgba(0, 0, 0, 0);

    overflow-y: auto;

    .pure-modal {
      & > * > .scrollable {
        overflow-x: auto;
      }

      @include ifmobile {
        width: 90%;
      }
    }
  }
}

.modal {
  padding-top: 8px;
  padding-bottom: 16px;

  :global {
    .panel {
      border-radius: 16px;
      box-shadow: $order-shadow;
      overflow: hidden;
    }

    .panel-heading {
      background: $primaryBlack;
    }

    .close {
      background: none;
      top: 44px;
      right: 40px;

      @include ifmobile {
        top: 12px;
        right: 8px;
      }
    }

    .panel-title {
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;

      padding: 0;

      @include ifmobile {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .panel-body {
      background: $white;

      padding: 20px 32px;
      @include ifmobile {
        padding: 16px 8px;
      }
    }
  }
}

.header {
  position: relative;

  @include ifmobile {
    word-break: break-word;
  }
}

.circleWrapper {
  position: relative;

  overflow: hidden;

  padding: 36px 44px;

  @include ifmobile {
    padding: 16px 24px;
  }
}

.violetCircle {
  position: absolute;

  width: 40%;
  height: 250%;

  filter: blur(100px);

  border-radius: 30%;

  bottom: -350px;
  right: 10%;

  background: $violet-gradient;

  transform: rotate(100deg);
}

.redCircle {
  position: absolute;
  width: 30%;
  height: 200%;

  filter: blur(100px);

  border-radius: 50%;

  bottom: -150%;
  left: 0;

  background: $circle-red;
}

.cartModal {
  :global {
    .close {
      display: none;
    }
  }
}
