@use "../../assets/scss/main.scss" as *;

.form {
  display: grid;
  gap: 32px;
}

.textWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: 500;

    color: $primaryBlack;
  }
}

.buttonswWrapper {
  display: flex;
  gap: 16px;
}

.cancelButton {
  padding: 14px 44px;

  border: 1px solid $primaryBlack;
}
